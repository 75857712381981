import * as React from 'react';
import { Link } from "gatsby"

// markup
const Footer = () => {
  return (
      <footer className="footer">
          <Link to={'/impressum'} className={'px-3'}>Impressum</Link>
          <Link to={'/datenschutz'}>Datenschutz</Link>
      </footer>
  )
}

export default Footer
