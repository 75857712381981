import logo from "../images/logo.svg"
import * as React from 'react';
import {Link} from 'gatsby';

// markup
const Navbar = () => {
  return (
      <nav className="navbar navbar-expand-lg fixed-top pt-0">
        <div className="container-sm py-3">
          <Link className="navbar-brand" to={"/"}>
            <img src={logo} alt="moghnacy logo svg" width="30" height="40"/>
          </Link>
          <div className="collapse navbar-collapse d-flex" id="navbarNav">
            <a href={'mailto:info@moghancy.com'} className="btn btn-primary ms-auto"><span>🎉</span>  Kontakt</a>
          </div>
          </div>
      </nav>
  )
}

export default Navbar
